// constants/ApiEndpoints.js

import {
  awsUrl,
  awsUrl2,
  dashboardUrl,
  demoAuthUrl,
  awsAcordUpload,
} from "../config/config";

export const ENDPOINTS = {
  TRIGGER_AMTRUST_QUOTE_GENERATION: `${awsUrl2}/api/triggerAmtrustQuoteGeneration`,
  TRIGGER_AMTRUST_SAVE_PAYMENT_PLANS: `${awsUrl2}/api/triggerAmtrustSavePaymentPlans`,
  TRIGGER_AMTRUST_GET_NET_RATES: `${awsUrl2}/api/triggerAmtrustGetNetRates`,
  GET_EXP_MOD_STATE_CATEGORY: `${awsUrl2}/api/getExpModStateCategory`,
  GET_STATE_AND_CITY: `${awsUrl2}/api/getStateAndCity`,
  RATER_MCP_STATE_DATA: `${awsUrl2}/api/raterMcpStateData`,
  GET_DOMAIN_INPUT_DATA: `${awsUrl2}/api/getDomainInputData`,
  GET_USER_DATA_STATUS: `${awsUrl}/api/getUserDataStatus`,
  USER_TABLE_DATA: `${awsUrl2}/api/userTableData`,
  UPDATE_USER_STATUS_TABLE: `${awsUrl2}/api/updateUserStatusTable`,
  GET_SCHEDULE_DEBIT_CREDIT_RANGE: `${awsUrl}/api/getScheduleDebitCreditRange`,
  POST_TABLES_DATA: `${awsUrl}/api/postTablesData`,
  GET_QUOTE_DETAILS: `${awsUrl}/api/getQuoteDetails`,
  GENERATE_QUOTE_PDF: `${awsUrl}/api/generateQuotePDF`,
  GET_RECALCULATE_QUOTE_ID: `${awsUrl}/api/getRecalculateQuoteId`,
  GET_RECALCULATE_QUOTE: `${awsUrl}/api/getRecalculateQuote`,
  RECALCULATE_QUOTE: `${awsUrl}/api/reCalculateQuote`,
  UNDERWRITER_DATA: `${awsUrl}/api/underWriterData`,
  RATER_TABLE_DATA: `${awsUrl2}/api/raterTableData`,
  GET_AGENT_BIND_RATIO: `${dashboardUrl}/api/getAgentBindRatio`,
  UPDATE_SOLVE_TRACKING_DATA_GENERIC: `${awsUrl2}/api/updateSolveTrackingDataGeneric`,
  UPDATE_SOLVE_TRACKING_DATA: `${awsUrl2}/api/updateSolveTrackingData`,
  GET_NET_RATE_AND_MANUALRATE: `${awsUrl2}/api/getNetRateAndManualrate`,
  GET_C_CODES: `${awsUrl2}/api/getCcodes`,
  DELETE_PROSPECT_DATA: `${awsUrl}/api/deleteProsepectData`,
  VERIFY_DEMO_REDIRECT_UUID: `${demoAuthUrl}/api/verifyDemoRedirectUuid`,
  GET_NO_LOSS_DATA: `${awsUrl2}/api/getNoLossData`,
  GET_MAP_CARRIER_ALL: `${dashboardUrl}/api/getMapCarrierAll`,
  GET_AGGREGATE_DATA: `${dashboardUrl}/api/getAggregateData`,
  SEND_CALLBACK_MAIL: `${awsUrl2}/api/sendCallbackMail`,
  GET_STATE_CODES_AND_DESC: `${awsUrl2}/api/getStateCodesAndDesc`,
  SET_QUOTE_STATUS: `${awsUrl}/api/setQuoteStatus`,
  GENERATE_PROPOSAL: `${awsUrl2}/api/generateProposal`,
  GET_LOSS_RUN_FORM: `${awsUrl}/api/getLossRunForm`,
  GENERATE_BIND_FORM: `${awsUrl2}/api/generateBindForm`,
  SEND_STATUS_EMAIL: `${awsUrl2}/api/sendStatusEmail`,
  GENERATE_ACCORD_FORM: `${awsUrl}/api/generateAccordForm`,
  GET_CARRIER_DATA_STATUS: `${awsUrl}/api/getCarrierDataStatus`,
  GET_QUOTES_EXPIRY: `${dashboardUrl}/api/getQuotesExpiry`,
  GET_EXPIRY: `${dashboardUrl}/api/getExpiry`,
  UPDATE_USERS_DATA: `${awsUrl}/api/updateUsersData`,
  GET_PRE_UNDERWRITER: `${awsUrl2}/api/getPreUnderwriter`,
  UPDATE_ENGAGED_WITH_EMAIL_CAMPAIGN: `${awsUrl2}/api/updateEngagedWithEmailCampaign`,
  GET_CARRIER_QUOTES: `${dashboardUrl}/api/getCarrierQuotes`,
  GET_CARRIER_PREMIUM: `${dashboardUrl}/api/getCarrierPremium`,
  GET_PIE: `${dashboardUrl}/api/getPie`,
  GET_BAR_GRAPH_ALL: `${dashboardUrl}/api/getBarGraphAll`,
  GET_BAR_GRAPH: `${dashboardUrl}/api/getBarGraph`,
  GET_PREMIUM_BY_CARRIER: `${dashboardUrl}/api/getPremiumByCarrier`,
  GET_QUOTES_BOUND_RATIO: `${dashboardUrl}/api/getQuotesBoundRatio`,
  GET_TOTAL_QUOTED_PREMIUM: `${dashboardUrl}/api/getTotalQuotedPremium`,
  GET_AMTRUST_DATA: `${awsUrl2}/api/getAmtrustData`,
  UPLOAD_DASHBOARD_COMMENTS: `${awsUrl2}/api/uploadDashboardComments`,
  GET_SALES_DASHBOARD_DATA_ADMIN: `${awsUrl2}/api/getSalesDashboardDataAdmin`,
  GET_DASHBOARD_TABLE_DATA: `${awsUrl2}/api/getDashboardTableData`,
  GET_NON_ELIGIBLE_DATA: `${awsUrl2}/api/getNonEligibleData`,
  PEO_CAMPAIGN_DATA_GET_ALL: `${awsUrl2}/api/peoCampaignData/getAll`,
  REMOVE_SALES_PERSON_FLAG: `${awsUrl2}/api/removeSalesPersonFlag`,
  UPLOAD_DOCS: `${awsUrl}/api/uploadDocs`,
  UPLOAD_RENEWAL_FILE: `${awsUrl2}/api/uploadRenewalFile`,
  PUT_EXTENSIS_RENEWAL_TABLE: `${awsUrl2}/api/putExtensisRenewalTable`,
  UPLOAD_FILE_STATUS_GET_DATA: `${awsUrl2}/api/upload_file_status/getData`,
  RATER_FILE_DATA_TYPE: `${awsUrl2}/api/raterFileData/{type}`,
  FILE_UPLOAD_ACORD: `${awsAcordUpload}/api/fileUploadAcord`,
  OCR_DOC_DEMO_ACORD: `${awsAcordUpload}/api/s3PollerJson/ocr-doc-demo-acord/{fileName}/us-east-1`,
  DELETE_FILE_FROM_S3: `${awsUrl2}/api/deleteFilefromS3`,
  DASHBOARD_FILE_DOWNLOAD: `${awsUrl2}/api/dashboardFileDownload`,
  UW_DASHBOARD_FILE_DOWNLOAD: `${awsUrl}/api/uwDashboardFileDownload`,
  SEND_EMAIL_ON_SUBMIT: `${awsUrl2}/api/sendEmail`,
  GENERATE_QADUOTE_PDF: `${awsUrl}/api/generateQAduotePDF`,
  GENERATE_ACORD_NCCI: `${awsUrl2}/api/generateAcordNcci`,
  GENERATE_ACORD_CA: `${awsUrl2}/api/generateAcordCA`,
  GENERATE_ACORD_FL: `${awsUrl2}/api/generateAcordFL`,
  UPDATE_SOLVE_USERS_DATA: `${awsUrl2}/api/updateSolveUsersData`,
  SEND_BIND_MAIL: `${awsUrl2}/api/sendBindMail`,
  UPDATE_SOLVE_USER_STATUS_TABLE: `${awsUrl2}/api/updateSolveUserStatusTable`,
  UPLOAD_LOSS_FORM: `${awsUrl}/api/uploadLossForm`,
  GET_PROGRAM_CARRIER: `${awsUrl2}/api/getProgramCarrier`,
  GET_MIN_MAX_PREMIUM: `${awsUrl}/api/getMinMaxPremium`,
  VERIFY_OTP: `${awsUrl2}/api/verifyOtp`,
  SEND_OTP_MAIL: `${awsUrl2}/api/sendOtpMail`,
  GET_DOMAIN: `${awsUrl2}/api/getDomain`,
  VALIDATE_CLASS_CODES_AND_DESCRIPTION: `${awsUrl2}/api/validateClassCodesAndDescription`,
  GET_RISK_PROFILE_DATA_ID: `${awsUrl}/api/getRiskProfileDataId`,
  GET_RISK_PROFILE_DATA: `${awsUrl}/api/getRiskProfileData`,
  SEND_RANDOM_PASSWORD: `${awsUrl}/api/sendRandomPassword`,
  PUT_USER_INTO_GROUP: `${awsUrl}/api/putUserIntoGroup`,
  GET_ENTITY_TYPE: `${awsUrl}/api/getEntityType`,
  GET_CARRIER_ELIG: `${awsUrl}/api/getCarrierElig`,
  GET_QUOTES_DATA_ID: `${awsUrl2}/api/getQuotesDataId`,
  GET_QUOTES_DATA: `${awsUrl2}/api/getQuotesData`,
  POST_USERS_DATA: `${awsUrl}/api/postUsersData`,
  GET_UW_QUES: `${awsUrl2}/api/getUwQuesData`,
  SEND_EMAIL_INDICATION: `${awsUrl2}/api/sendQuote`,
};
