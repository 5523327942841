import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";
import { Auth } from "aws-amplify";
import styles from "./HeaderSubPart.module.scss";
import { LibertateContext } from "../../context/Context";

let isMobile = window.innerWidth < 900;
class HeaderSubPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userGroups: [],
    };
  }

  componentDidMount = () => {
    let logoSize;
    let hrf = window.location.href;
    if (!hrf.includes("toolbar")) {
      if (hrf.includes("Quote")) {
        logoSize = "small";
      } else {
        logoSize = "large";
      }
    }
    if (logoSize) this.setState({ logoSize });

    let isLoggedIn,
      emailID,
      userGroups = [];
    try {
      isLoggedIn = this.props.sessionData;
      // console.log("isLoggedIn: ", isLoggedIn);
      if (isLoggedIn?.attributes?.name) {
        emailID = isLoggedIn.attributes.name;
      } else {
        emailID = isLoggedIn.attributes.email.split("@")[0];
      }
      let userGroups =
        isLoggedIn.signInUserSession.accessToken.payload["cognito:groups"];
      this.setState({ userGroups });
      // console.log("emailID: ", emailID, userGroups);
    } catch (error) {
      // console.log("comp did update err", error);
    }

    if (emailID) {
      this.setState({ emailID });
    }
  };

  render() {
    let { emailID, userGroups } = this.state;
    let { resetEntireState } = this.props;
    let quoteGroups = [process.env.REACT_APP_GET_QUOTE_GROUP];
    let dashboardGroups = [process.env.REACT_APP_DASHBOARD_GROUP];
    let pathname = window.location.pathname.toLowerCase();
    let isSafetyPage = pathname.includes("safety");
    let isworkersPage = pathname.includes("workers");
    let isResourcesPage = pathname.includes("resources");
    let displayQuote =
      !window.location.pathname.toLowerCase().includes("quote") &&
      userGroups.some((e) => quoteGroups.includes(e));
    let displayDashboard =
      !(window.location.pathname.toLowerCase() === "/dashboard") &&
      userGroups.some((e) => dashboardGroups.includes(e));
    let displayRaterDashboard =
      !(window.location.pathname.toLowerCase() === "/raterdashboard") &&
      userGroups.some((e) => dashboardGroups.includes(e));
    let adminGroups =
      userGroups.includes(process.env.REACT_APP_ADMIN_GROUP) ||
      userGroups.includes(process.env.REACT_APP_UW_GROUP);
    let baGroups = userGroups.includes(process.env.REACT_APP_LANDING_GROUP);

    return (
      <>
        {emailID && (
          <div
            className={`d-flex mt-4 justify-content-between ${styles.navcontent}`}
          >
            {!window.location.pathname.includes("landingpage") &&
              !isSafetyPage &&
              !isworkersPage &&
              !isResourcesPage &&
              displayQuote &&
              !adminGroups && (
                <Link
                  to={"/quote"}
                  className="dashboard-link text-dark"
                  onClick={() => {
                    resetEntireState();
                  }}
                >
                  <b>New Submission</b>
                </Link>
              )}

            {!window.location.pathname.includes("landingpage") &&
              !isSafetyPage &&
              !isResourcesPage &&
              !isworkersPage &&
              (displayRaterDashboard || displayDashboard) &&
              displayDashboard && (
                <Link
                  className="dashboard-link text-dark bold-text"
                  to={"/dashboard"}
                >
                  <b className={styles.boldText}>Dashboard</b>
                </Link>
              )}
            {!isMobile && (
              <div className="d-flex align-items-start dropdown">
                <span
                  className="dropdown-toggle text-dark navbar-font"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <b className={styles.boldText}> {emailID}</b>
                </span>
                <div className={`dropdown-menu ${styles.dropdownmenu}`}>
                  <a
                    className={`dropdown-item ${styles.dropdownmenu_item}`}
                    onClick={() => {
                      logout();
                    }}
                  >
                    Logout
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default function HeaderSubPartWrapper(props) {
  return (
    <LibertateContext.Consumer>
      {(context) => <HeaderSubPart {...props} {...context} />}
    </LibertateContext.Consumer>
  );
}
