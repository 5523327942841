import { ENDPOINTS } from "../constants/ApiEndpoints";
import axiosInstance from "../middleware/AxiosInstance";

class CommonApi {
  constructor() { }
  async getExpModStateCategory(stateList) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GET_EXP_MOD_STATE_CATEGORY,
        JSON.stringify({ states: stateList })
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getStateAndCity(zipCode, stateList) {
    try {
      const response = await axiosInstance.post(
        `${ENDPOINTS.GET_STATE_AND_CITY}/${zipCode}`,
        JSON.stringify({ stateList })
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async raterMcpStateData(stateList, programList) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.RATER_MCP_STATE_DATA,
        JSON.stringify({ stateList, programList })
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getDomainInputData(domain) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_DOMAIN_INPUT_DATA}/${domain}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getUserDataStatus(user, type) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_USER_DATA_STATUS}/${user}/${type}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async userTableData(userDetails, type) {
    try {
      const response = await axiosInstance.post(
        `${ENDPOINTS.USER_TABLE_DATA}/${type}`,
        userDetails
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async updateUserStatusTable(requestBody) {
    console.log(requestBody, "requestBody")
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.UPDATE_USER_STATUS_TABLE,
        requestBody
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getScheduleDebitCreditRange(state) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_SCHEDULE_DEBIT_CREDIT_RANGE}/${state}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async postTablesData(tableName, data) {
    try {
      const response = await axiosInstance.post(
        `${ENDPOINTS.POST_TABLES_DATA}/${tableName}`,
        JSON.stringify(data)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getQuoteDetails(etQuoteDetails) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GET_QUOTE_DETAILS,
        etQuoteDetails
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async generateQuotePDF(getQuoteDetails) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GENERATE_QUOTE_PDF,
        JSON.stringify(getQuoteDetails)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getRecalculateQuoteId(dataBody) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GET_RECALCULATE_QUOTE_ID,
        JSON.stringify({ body: dataBody })
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getRecalculateQuote(id) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_RECALCULATE_QUOTE}/${id}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async reCalculateQuote(body) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.RECALCULATE_QUOTE,
        body
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async underWriterData(data, type) {
    try {
      const response = await axiosInstance.post(
        `${ENDPOINTS.UNDERWRITER_DATA}/${type}`,
        data
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async raterTableData(req_body, type) {
    try {
      const response = await axiosInstance.post(
        `${ENDPOINTS.RATER_TABLE_DATA}/${type}`,
        req_body
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getAgentBindRatio(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_AGENT_BIND_RATIO}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async updateSolveTrackingDataGeneric(requestBody, type) {
    try {
      const response = await axiosInstance.post(
        `${ENDPOINTS.UPDATE_SOLVE_TRACKING_DATA_GENERIC}/${type}`,
        JSON.stringify(requestBody)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async updateSolveTrackingData(requestBody) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.UPDATE_SOLVE_TRACKING_DATA,
        JSON.stringify(requestBody)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getNetRateAndManualrate(request) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GET_NET_RATE_AND_MANUALRATE,
        request
      );
      console.log("getNetRateAndManualrateResponse", response);
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getCcodes(state, value) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_C_CODES}/${state?.toLocaleLowerCase()}/${value}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async deleteProsepectData(userName) {
    try {
      const response = await axiosInstance.delete(
        `${ENDPOINTS.DELETE_PROSPECT_DATA}/${userName}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async verifyDemoRedirectUuid(id, path) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.VERIFY_DEMO_REDIRECT_UUID}/${id}/libertate/${path}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getNoLossData(requestBody) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GET_NO_LOSS_DATA,
        JSON.stringify(requestBody)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getMapCarrierAll(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_MAP_CARRIER_ALL}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getAggregateData(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_AGGREGATE_DATA}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async sendCallbackMail(email, name, phone, type) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.SEND_CALLBACK_MAIL,
        JSON.stringify({ email, name, phone, type })
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getStateCodesAndDesc(state) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_STATE_CODES_AND_DESC}/${state}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async setQuoteStatus(setQuoteStatus) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.SET_QUOTE_STATUS,
        JSON.stringify(setQuoteStatus)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async generateProposal(etQuoteDetails) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GENERATE_PROPOSAL,
        JSON.stringify(etQuoteDetails)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getLossRunForm(lossRunData) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GET_LOSS_RUN_FORM,
        JSON.stringify(lossRunData)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async generateBindForm(body) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GENERATE_BIND_FORM,
        body
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async sendStatusEmail(emailDetails) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.SEND_STATUS_EMAIL,
        JSON.stringify(emailDetails)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async generateAccordForm(etQuoteDetails) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GENERATE_ACCORD_FORM,
        etQuoteDetails
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getCarrierDataStatus() {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_CARRIER_DATA_STATUS}/carrier_n@gmail.com`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getQuotesExpiry(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_QUOTES_EXPIRY}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getExpiry(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_EXPIRY}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async updateUsersData(data2send) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.UPDATE_USERS_DATA,
        data2send
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getPreUnderwriter() {
    try {
      const response = await axiosInstance.get(ENDPOINTS.GET_PRE_UNDERWRITER);
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async updateEngagedWithEmailCampaign(domain) {
    try {
      const response = await axiosInstance.post(
        `${ENDPOINTS.UPDATE_ENGAGED_WITH_EMAIL_CAMPAIGN}/${domain}`,
        JSON.stringify(domain)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getCarrierQuotes(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_CARRIER_QUOTES}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getCarrierPremium(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_CARRIER_PREMIUM}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }
  async getPie(user) {
    try {
      const response = await axiosInstance.get(`${ENDPOINTS.GET_PIE}/${user}`);
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getBarGraphAll(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_BAR_GRAPH_ALL}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getBarGraph(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_BAR_GRAPH}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getPremiumByCarrier(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_PREMIUM_BY_CARRIER}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getQuotesBoundRatio(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_QUOTES_BOUND_RATIO}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getTotalQuotedPremium(user) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_TOTAL_QUOTED_PREMIUM}/${user}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getAmtrustData(uuid, apiType) {
    try {
      const response = await axiosInstance.get(
        `${ENDPOINTS.GET_AMTRUST_DATA}/${uuid}/${apiType}`
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }

  async getUwQues(classCodes) {
    try {
      const response = await axiosInstance.post(
        ENDPOINTS.GET_UW_QUES,
        JSON.stringify(classCodes)
      );
      return response;
    } catch (error) {
      console.error("Error in API request:", error);
      throw new Error("Error in API request: " + error);
    }
  }
}

export default new CommonApi();
